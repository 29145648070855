#startScreen {
    padding: 2em;
    padding-bottom: 5em;
    text-align: center;
}

#welcomeMessage {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 1em;
}

#welcomeMessage h2 {
    margin: 0;
    font-size: 3.5em;
}

#welcomeMessage h1 {
    text-transform: uppercase;
    font-weight: 100;
    font-size: 1.7em;
    margin: 0 auto;
}

#welcomeMessage p {
    font-weight: bold;
    line-height: 1.4;
    /* max-width: 500px; */
    margin: 0 auto;
}

#welcomeMessage p>span {
    display: block;
}

#welcomeMessage img {
    max-width: 100%;
    margin: 2em auto;
}

.featuredBusiness,
.newBusinesses {
    width: 100%;
    margin-top: 3.5em;
    /* padding: 2em; */
}

.featuredBusiness h3,
.newBusinesses h3 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2.3em;
}

.newBusinesses .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    width: 100%;
}

.featuredBusiness .listItem .icons,
.newBusinesses .listItem .icons {
    display: none;
}

.featuredBusiness .listItem h2,
.newBusinesses .listItem h2 {
    width: 100%;
    text-align: center;
}

/* @media only screen and (max-width: 1020px) {
    .newBusinesses .grid-col-3{
        grid-template-columns: 1fr 1fr;
    }
} */

/* @media only screen and (max-width: 600px) {
    .newBusinesses .grid-col-3{
        grid-template-columns: 1fr;
    }
} */

@media only screen and (max-width: 400px) {
    #startScreen {
        padding: 1em;
        padding-bottom: 5em;
    }

    .featuredBusiness h3,
    .newBusinesses h3 {
        font-size: 1.8em;
    }

    .newBusinesses .listItem h2 {
        font-size: .8em;
    }
}