body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.update-notification {
  background-color: #000;
  color: #fff;
  display: block;
  position: absolute;
  bottom:0;
  right:0;
  padding:0.8em 1.2em;
  text-decoration: none;
}

	/*
  	Flaticon icon font: Flaticon
  	Creation date: 27/04/2019 19:59
  	*/

@font-face {
  font-family: "Flaticon";
  src: url(/static/media/Flaticon.70546a37.eot);
  src: url(/static/media/Flaticon.70546a37.eot?#iefix) format("embedded-opentype"),
       url(/static/media/Flaticon.dc1b38a1.woff2) format("woff2"),
       url(/static/media/Flaticon.d781c19e.woff) format("woff"),
       url(/static/media/Flaticon.1c023bed.ttf) format("truetype"),
       url(/static/media/Flaticon.da67d14a.svg#Flaticon) format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.da67d14a.svg#Flaticon) format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
/* margin-left: 20px; */
}

.flaticon-home:before { content: "\F100"; }
.flaticon-house:before { content: "\F101"; }
.flaticon-setup:before { content: "\F102"; }
.flaticon-gear:before { content: "\F103"; }
.flaticon-mail:before { content: "\F104"; }
.flaticon-internet:before { content: "\F105"; }
.flaticon-heart:before { content: "\F106"; }
.flaticon-heart-1:before { content: "\F107"; }
.flaticon-clock:before { content: "\F108"; }
.flaticon-hour:before { content: "\F109"; }
.flaticon-user:before { content: "\F10A"; }
.flaticon-supermarket:before { content: "\F10B"; }
.flaticon-website:before { content: "\F10C"; }
.flaticon-lock:before { content: "\F10D"; }
.flaticon-list:before { content: "\F10E"; }
.flaticon-left-arrow:before { content: "\F10F"; }
.flaticon-left-arrow-1:before { content: "\F110"; }
.flaticon-information-button:before { content: "\F111"; }
.flaticon-info:before { content: "\F112"; }
* {
    font-family: 'Montserrat';
    box-sizing: border-box;
}

*:focus { 
    outline: none;
}

body {
    /* background-color: #000000; */
}

body, html, #root{
    position: relative;
    width: 100%;
    height: 100%;
}

.app {
    background-color: #fff;
    position: relative;
    padding-bottom: 2.7em;
}

a, a:visited{
    text-decoration: none;
    color: inherit;
}

h2{
    font-weight: 900;
    text-transform: uppercase;
    font-size: 3em;
    margin-bottom: .5em;
}

.subline{
    text-transform: uppercase;
    font-size: 1.5em;
}



.shareButtons {
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-bottom: 3em;
    /* margin: .5em; */
}

.shareButtons>div{
    /* line-height: 2; */
    margin-top: 1em;
}

.shareButtons .share-button {
    display: inline-block;
    margin: .2em;
    margin-bottom: 1em;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.app{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
}

/* .navBar{
    z-index: 2;
    position: fixed;
    width: 100%;
    color:black;
    text-align: right;
    padding: 1em;
    line-height: 1;
}

.navBar .textlink {
    margin-left: 1em;
    cursor: pointer;
}

.navBar .imprint {
    float: left;
}

.navBar a:hover,
.navBar .textlink:hover {
    font-size: bold!important;
    text-transform: underline!important;
}

.navBar .logo{
    float: left;
}

.navBar a, .navBar .links{
    display: inline-block;
}

.navBar .links a{
    cursor: pointer;
}

.navBar a.active{
    font-weight: bold;
} */

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    width: 100vw;
    /* padding-bottom: 3em; */
    /* padding-top: 3.2em; */
    /* overflow: auto; */
    /* background-color: red; */
}

.container.margin-top {
    margin-top: 5em;
}

.verifyMessage {
    width: 20em;
    height: 100%;
    margin: 5em auto;
    text-align: center;
}

.verifyMessage a {
    text-decoration: underline;
    font-weight: bold;
}

.verifyMessage img {
    height: 65%;
}

#signup_woman img{
    max-height: 50vh;
    width: auto;
}

#signup-intro{
    display: inline-flex;
    width: auto;
    height: 100vh;
    text-align: center;
}

#signup-intro .illustration {
    flex: 3 1;
    max-width: 70vh;
    text-align: center;
}

.illustration>img{
    width: 100%;
    height: auto;
    margin: 0 auto;
}

#signup-intro .select-mode {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

#signup-intro .select-mode button,
#signup-intro .select-mode .button{
    /* margin: .5em; */
    width: 12em;
    /* height: 5.3em; */
}

#signup-intro h2{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 1em;
}

#signup-intro .subline{
    text-align: center;
}

#signup-intro .shareButtons>div:first-child {
    margin-top: 2em;
}

.signup-message {
    text-align: center;
}

.signup-message h2{
    margin-bottom: 0;
}

.signup-message p {
    margin-top: 0;
    font-size: 1.5em
}

.progressBar{
    position: fixed;
    width: 100%;
    height: 1em;
    /* background-color: gray; */
}

.progress{
    position: relative;
    height: .3em;
    width: 3em;
    min-width: 5px;
    background-color: gray;
    border-radius: 0px 5px 5px 0px;
    transition: width .2s cubic-bezier(0.215, 0.610, 0.355, 1);
}

form{
    width: 100%;
    /* max-width: 40em; */
    padding: 2em;
    padding-bottom: 5em;
    padding-left: 5em;
    padding-right: 5em;
}

.inputContainer{
    position: relative;
    /* background: green; */
    padding-top: 1em;
    margin-bottom: 5em;
}

.inputContainer.checkbox{
    margin-bottom: 3em;
}

.inputContainer>label{
    /* display: flex;
    flex-direction: row;
    align-items: flex-end; */
    font-size: 1em;
    width: 100%;
    /* display: block; */
}

.private .inputContainer>label{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* justify-content: center; */
}

.labelText { 
    display: block;
    flex: 1 1;
    text-transform: uppercase;
    font-weight: bold;
}

.checkbox .labelText{
    display: inline-block;
}

.inputContainer textarea{
    display: block;
    border: 1px solid black;
    resize: none;
    width: 50%;
    height: 8em;
    margin-top: .5em;
    font-size: 1em;
    padding: 1em;
    /* margin-bottom: 3em; */
}

.inputContainer textarea.disabled{
    display: none;
}

.inputContainer label.file{
    /* flex: 0; */
    display: block;
    margin-bottom: 3em;
    /* margin-top: 2em; */
    width: 15em;
    height: 10em;
    /* height: 0; */
    /* background-color: black; */
    border-radius: 50%;
    margin: 0 auto;
    overflow: visible;
    cursor: pointer;
}

label.file .labelText{
    /* flex: 0; */
    position: absolute;
    top: -2em;
    left: 0%;
    width: 100%;
    /* border: 1px solid black; */
    padding: .5em 1em;
    cursor: pointer;
    text-align: center;
    /* width: 30em; */
}

label.checkboxLabel {
    display: inline;
}

#logo-img {
    background-color: white;
}

.bg-black #logo-img {
    background-color: black;
}

#logo-img .logo-container {
    width: 15em;
    height: 10em;
    /* background-color: black; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid black;
    /* border-radius: 50%; */
}

.display-fit #logo-img .logo-container {
    background-size: contain;
}

.logoGroup {
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
    padding-top: 3em;
    margin-bottom: 3em;
}

.logoOptions {
    margin: 0 2em;
}

.logoOptions .inputContainer {
    margin-bottom: 1em;
}

.logoOptions .radio-div {
    margin-right: 2em;
}

label .fileName {
    position: absolute;
    /* bottom: -2em; */
    width: 100%;
    left: 0%;
    text-align: center;
    font-weight: bold;
}

.inputContainer input{
    display: block;
    flex: 1 1;
    width: 50%;
    font-size: 1em;
    font-weight: bold;
    padding-top: 1em;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    /* margin-bottom: 3em; */
}

.inputContainer.email,
.inputContainer.contactPerson {
    display: inline-block;
    width: calc(50% - 1em);
}

.inputContainer.email{
    margin-right: 2em;
}

.inputContainer.email input,
.inputContainer.contactPerson input{
    width: 100%;
} 

.inputContainer input:focus{
    border-bottom-width: 2px;
}

input[type="file"] {
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

input[type="checkbox"] {
    display: inline;
    width: 1em!important;
}

.inputContainer input[type="radio"] {
    width: 1em;
}

.inputContainer.hasError input{
    border-color: red;
}

.inputContainer .errorMessage{
    display: none;
    position: absolute;
    color: red;
    top: 100%;
    padding-top: .3em;
}

.inputContainer .errorMessage.info{
    display: block;
    color: darkgoldenrod;
}

.inputContainer.hasError .errorMessage{
    display: block;
}

.radio-group {
    display: flex;
    align-items: flex-end;
    width: 100%;
    text-align: center;
}

.radio-div{
    /* text-align: center; */
    /* display: inline-block; */
    margin-right: 1em;
}

.radio-div input{
    display: inline-block!important;
    /* margin: 0 auto;
    text-align: center; */
}

.radio-div img{
    max-width: 100%;
    padding: 1em;
}

.dropdown{
    /* margin: 2em 0; */
    margin-top: .5em;
    padding: .5em 1em;
    /* border: 1px solid black; */
    background-color: black;
    color: white;
    margin-bottom: 3em;
    width: 50%;
}

.dropbtn{
    cursor: pointer;
}

.dropbtn i{
    width: 1em;
    height: 1em;
    margin-left: 1em;
    /* padding: 1em; */
    /* background: red; */
    transition: .1s linear;
    vertical-align: middle;
}

.closed .dropbtn i{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg)
}

.dropbtn>*{
    display: inline-block;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.collapsable{
    transition: max-height 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    overflow: hidden;
}

.open .collapsable{
    max-height: 300px;
    /* background-color: green; */
}

.closed .collapsable{
    max-height: 0;
    /* background-color: red; */
}

.dropdown .search{
    position: relative;
}

.dropdown .search>input {
    width: 100%;
    /* border: none; */
    font-size: 1em;
    font-weight: normal;
    border: none;
    padding: .2em;
    padding-right: 2em;
    margin-bottom: 0;
    background-color: #eee;
    border-radius: 5px
}

.dropdown .search>i{
    position: absolute;
    opacity: 1;
    top: 0;
    right: 0;
    font-size: 1.6em;
    cursor: pointer;
    transition: opacity .2s linear;
}

.dropdown .search>input:placeholder-shown~i{
    opacity: 0;
    pointer-events: none;
}

.dropdown .collapsable>div{
    margin: .5em 0;
}

.dropdown .collapsable>div:not(.selectedOption):hover{
    background: lightgray;
}

/* .openOptions {
    border-bottom: 1px solid black;
    margin-top: 3em;
    padding-bottom: 1em;
}

.daygroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    margin-top: 1em;
    margin-bottom: 1em;
}

.daygroup.closed{
    align-items: center;
} */

/* .timeOptions{
    flex: 1;
    text-align: left;
}

.closed .timeOptions{
    font-weight: bold;
    text-transform: uppercase;
}

.daygroup .textLink{
    margin-top: 1em;
    text-decoration: underline;
}

.addOption{
    text-transform: uppercase;
    text-align: center;
    text-decoration: underline;
} */

.weekdayContainer {
    position: relative;
    /* display: flex; */
    align-items: center;
    margin-top: 2em;
}

.weekday{
    display: inline-block;
    width: 3em;
    height: 3em;
    font-size: .8em;
    margin-right: 1em;
    background-color: gray;
    color: white;
    border-radius: 50%;
    line-height: 3em;
    text-align: center;
    cursor: pointer;
}

.weekday.selected{
    background-color: black;
}

.periods{
    display: inline-block;
    vertical-align: text-top;
}

.period {
    margin-bottom: 1em;
}

.addPeriod,
.removePeriod {
    display: inline-block;
    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 1em;
    font-size: 1.5em;
    /* font-weight: bold; */
    /* background-color: black;
    color: white; */
    /* border: 1px solid black; */
    cursor: pointer;
    border-radius: 50%;
}

.removePeriod {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg)
}

/* .period::after {
    content: " - ";
    padding: 0 1em;
} */

.period input {
    /* width: 5em!important; */
}

.period.hidden{
    display: none;
}

.period>*{
    display: inline-block;
    margin: 0!important;
}

.period input{
    width: auto !important;
}

.weekdayContainer .errorMessage {
    display: block;
}

.times input {
    width: auto;
    padding: 0;
    margin-bottom: 0;
    display: inline-block;
}

.times .timeInputs i{
    font-size: 1em;
    padding: .5em;
}

.illufield {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
    margin-bottom: 10em;
}

.illufield.signup{
    margin-bottom: 1em;
}

.illufield>.inputContainer {
    flex: 2 1;
    margin-bottom: 0!important;
    max-width: 50vw;
}

.illufield .illustration {
    flex: 1 1;
    text-align: center;
}

.illufield #signup_woman{
    flex: 2 1;
}

.illufield img {
    max-height: 15em;
    width: auto;
    margin: 0 auto;
}

.illufield #signup_woman img{
    max-height: 30em;
}

.illufield input,
.illufield textarea {
    width: 100%;
}

.errorMessages>div {
    color: red;
}

.errorMessages>div:first-child {
    color: black;
}

.sdg-grid {
    display: grid;
    /* grid-auto-columns: 1fr; */
    /* grid-auto-flow: column; */
    grid-template-columns: repeat(9, 1fr);
    /* grid-auto-rows: min-content; */
    grid-gap: 1em;
}

.sdg-grid label {
    display: block;
    justify-self: stretch;
    align-self: stretch;
    cursor: pointer;
    /* grid-column: 1fr; */
}

.sdg-grid label.off{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.sdg-grid label span {
    display: block;
    font-size: .8em;
}

.sdg-grid img {
    padding: 0;
    max-width: 100%;
}

/* .times .day{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: .5em;
}

.times label{
    display: inline-block;
    text-transform: capitalize;
    line-height: 2
}

.times>label{
    margin-bottom: 1em
}

.times .timeInputs{
    /* flex: 1; */
    /* text-align: right;
} */



.login .inputContainer.password {
    margin-bottom: 0;
}

.login a{
    display: block;
    width: 50%;
    text-align: right;
    text-decoration: underline;
    margin-top: 1em;
    margin-bottom: 5em;
}

.forgot-password button {
    display: block;
}

.authErrorMessage{
    position: fixed;
    z-index: 3;
    top: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 2em;
    box-shadow: 0px 1px 15px rgba(0,0,0,.7);
    border-radius: 10px;
}

.bottom-message {
    position: fixed;
    z-index: 3;
    width: 100%;
    /* min-height: 3em; */
    bottom: 0;
    background-color: black;
    color: white;
    padding: 1em;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    /* line-height: 3em; */
}

.map{
    width: 100%;
    height: 100%;
}

.mapContainer{
    position: relative;
    width: 100vw;
    height: 100vh;
    background: black;
    top: 0;
    left: 0;
}

.marker{
    position: relative;
    width:2em;
    height: 2em;
    background-color: white;
}

.marker img{
    max-width: 100%;
    max-height: 100%;
}

.dashboard {
    /* text-align: center; */
    width: 100%;
}

.dashboard h3{
    margin-top: 0;
    /* margin-bottom: 3em; */
}

.dashboard .settings {
    display: grid;
    width: 100%;
    max-width: 40em;
    grid-template-columns: 33% auto 33%;
    grid-row-gap: 2em;
    grid-column-gap: 1em;
    margin-top: 3em;
    /* display: flex; */
    /* justify-items: center;
    align-items: center; */
} 

.dashboard .property {
    grid-column-start: 1;
    text-align: right;
}

.dashboard .value{
    position: relative;
    grid-column-start: 2;
    font-weight: bold;
}

.dashboard .link{
    grid-column-start: 3;
    grid-column-end: 3;
}

.dashboard .option{
    text-align: center;
}

.dashboard .value input {
    font-size: 1em;
    height: 1em;
    padding-top: 0;
}

.dashboard .value .inputContainer {
    /* display: inline; */
    /* flex: 3; */
    width: 100%;
    margin: 0;
    padding: 0;
    /* width: 15em; */
}

/* .dashboard .value .inputContainer,
.dashboard .value label,
.dashboard .value input{
    display: inline;
} */

.dashboard .text-link {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    margin-left: 1em;
    margin-right: 1em;
    /* margin-left: 2em; */
    /* margin-top: 1em; */
}

.email-handler-message {
    margin-top: 3em;
    padding: 1em;
    
    background-color: #eee;
}

.reset-info {
    margin-top: 3em;
}

form.reset-pw{
    width: 50%;
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    .sdg-grid {
        grid-template-columns: repeat(6, 1fr);
    }
}


@media only screen and (max-width: 800px) {
    form{
        padding-bottom: 5em;
        padding-left: 1em;
        padding-right: 1em;
    }

    .progressBar {
        display: none;
    }
    
    .inputContainer{
        margin-bottom: 3em;
    }
    
    .inputContainer.checkbox{
        margin-bottom: 2em;
    }
    
    .checkbox .labelText{
        display: inline-block;
    }
    
    .inputContainer textarea,
    .inputContainer input{
        width: 100%;
    }

    .period input{
        width: auto;
    }

    .illufield {
        display: block;
        margin-bottom: 3em;
    }

    .illufield.times{
        display: flex;
        flex-direction: column-reverse;
    }
    
    .illufield>.inputContainer {
        flex: 1 1;
        margin-bottom: 0!important;
        max-width: 100%;
        width: 100%;
    }

    .dropdown{
        width: 100%;
    }

    .inputContainer.email,
    .inputContainer.contactPerson {
        display: block;
        width: 100%;
    }

    /* .inputContainer label.file .labelText{
        margin-top: 3em;
    }

    .inputContainer label.file{
        display: block;
        margin-bottom: 5em;
        margin-top: 3em;
    } */

    input[type="file"] {
        width: 0.1px!important;
    }

    .radio-div img{
        /* max-width: 100%; */
        padding: 0;
    }

    .daygroup{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 1em;
        margin-bottom: 3em;
    }

    .timeOptions{
        margin-bottom: 1em;
        text-align: center;
    }

    .addOption{
        margin-bottom: 3em
    }

    .select-mode {
        text-align: center;
    }

    .bottom-message {
        font-size: .7em;
        font-weight: normal;
        padding: 1em .5em;
        /* line-height: 3em; */
    }

    .sdg-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    

    #signup-intro{
        display: flex;
    }

    #signup-intro .select-mode {
        /* display: inline-block; */
        flex-direction: column;
        align-items: center;
    }

    #signup-intro .select-mode .button:first-child{
        margin-bottom: .5em;
    }
}

@media only screen and (max-width: 600px) {
    .sdg-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 400px) {
    .logoGroup {
        display: block;
    }

    .sdg-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
#settingsContainer {
    position: relative;
    width: 100%;
    max-width: 400px;
    padding: 1em;
    margin: 0 auto;
    /* align-items: center; */
}

.settingsSection h3 {
    text-transform: uppercase;
    font-size: 1em;
    margin-bottom: .3em;
    margin-top: 2em;
}

#settingsContainer .messages {
    margin-top: 2em;
    text-align: center;
    padding: 1em;
    background-color: #f2f2f2;
    border-radius: .3em;
}

/* .settingsSection {
    display: flex;
    flex-direction: 
} */

.settingsSection a,
.settingsSection .link {
    display: block;
    width: 100%;
    max-width: 400px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: .8em;
    margin-bottom: .3em;
    border-radius: .3em;
    font-weight: bold;
    font-size: .8em;
    cursor: pointer;
}

.singleSetting {
    width: 100%;
    padding: 2em;
}

.singleSetting .errorMessage {
    margin-top: 1em;
}

.editProfile {
    position: relative;
}

.editProfile h1 {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 0;
}

.editProfile .closeBtn {
    position: absolute;
    right: 1em;
    top: 1em;

}


.H_ui {
    /* width: 100%;
    height: 100%; */
}

.H_ib {
    /* background-color: red !important; */
    /* width: 10em;
    height: 10em; */
    width: 0;
    height: 0;
    /* transform: translate(100px, 20px) !important; */
}

.H_ib_body {
    /* position: absolute; */
    left: 0px;
    bottom: 0px !important;
    width: 10em;
    /* transform: translateY(-80%); */
    /* height: auto; */
    /* width: 10em;
    height: 10em; */
    background-color: transparent !important;
    /* color: white; */
}

.H_ib_tail {
    display: none;
}

.H_ib_close {
    z-index: 5;
    position: absolute;
    right: 0;
    /* color: black !important; */
}

.H_ib_close::after {
    content: "+";
    position: absolute;
    right: 0;
    color: black;
    font-size: 3em;
    /* border: 1px solid black; */
    /* font-weight: bold; */
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.H_ib_close .H_icon {
    color: transparent !important;
    /* background-color: red; */
    display: none;
    /* color: white; */
}

.H_ib_content {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    width: 10em;
    height: 10em;
    color: white;
}

img.bubble-img {
    /* position: absolute; */
    z-index: -1;
    width: 10em !important;
    height: 10em !important;
}

.H_ib_body .details {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    padding: 1em;
    box-sizing: border-box;
    overflow: hidden;
    /* padding-bottom: 80%; */
    top: 0;
    font-size: .6em;
    text-align: center;
    /* background-color: red */
}

.H_ib_body .name {
    flex: 0 1;
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: bold;
}

.H_ib_body .industry {
    flex: 0 1;
    line-height: 1.2;
    margin-bottom: .5em;
}

.H_ib_body .address>div {
    flex: 0 1;
    margin: 0;
    line-height: 1.2;
}

.H_ib_body .url {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 2em; */
    margin-top: .5em;
    /* background: red; */
}

.H_ib_body .url img {
    max-height: 2em;
    width: auto;
}

.H_ib_body .dots {
    flex: 0 1;
    /* width: 10em; */
    text-align: center;
    /* height: 1em; */
}

.H_ib_body .dots>span {
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: #fff;
    margin: 3px;
    border-radius: 50%;
}
.info-container {
    display: flex;
    /* display: none; */
    position: absolute;
    z-index: 3;
    left: 1em;
    right: 1em;
    bottom: 4em;
    padding: 1em;
    background-color: #fff;
    border-radius: .5em;
}

.info-container .close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2em;
    height: 1em;
    width: 1em;
    text-align: center;
    line-height: 1em;
    font-weight: bold;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    /* background-color: grey; */
}

.info-container .info {
    padding-right: 6em;
    max-width: 100%;
}

.info-container h2 {
    font-size: 2em;
    margin: 0;
}

.info-container>div {
    flex: 1 1;
}

.info-container .logo {
    flex: 0 1;
    width: 12em;
    height: 9em;
    flex-basis: 12em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 1em;
}

.info-container .industry {
    font-size: .8em;
    margin-bottom: .5em;
}

.info-container .icons {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
}

.info-container .icons>div>* {
    display: inline-block;
}

.info-container .icons-right i {
    margin-left: .5em;
}

.info-container .openingTimes {
    position: absolute;
    /* position: relative; */
    right: 0;
    margin-left: 1em;
    font-size: .8em;
    /* padding: .5em;
    padding-top: 0; */
    bottom: calc(100% - 4em);
    top: 2em;
    transition: .2s bottom ease-out; 
    overflow: hidden;
}

.info-container .openingTimes.expanded {
    transition-delay: 0s;
    bottom: 3.5em;
    /* max-height: 500px; */
    overflow: auto;
}

.info-container .openingTimes>span {
    padding: .5em;
    background-color: rgba(255,255,255,0);
    transition: .1s background-color linear;
    transition-delay: .2s;
    cursor: pointer;
}

.info-container .openingTimes.expanded>span {
    background-color: rgba(255,255,255,.9);
    transition-delay: 0s;
}

.info-container .openingTimes i {
    height: 1.2em;
    line-height: 1.2;
    vertical-align: middle;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: .3s transform linear;
}

.info-container .openingTimes.expanded i {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

.info-container .openingTimes>div {
    left: 0;
    right: 0;
    padding: .5em;
    padding-top: 0;
    background-color: rgba(255,255,255,0);
    transition: 0s background-color linear;
    transition-delay: .3s;
}

.info-container .openingTimes.expanded>div {
    background-color: rgba(255,255,255,.9);
    transition-delay: 0s;
}

.info-container .openingTimes .day h4 {
    margin-bottom: 0;
    margin-top: .8em;
}

.info-container .openingTimes .day:first-of-type h4 {
    margin-top: 0;
}

.info-container .openingTimes .hide {
    display: none;
}

.messageContainer {
    position: absolute;
    max-width: 500px;
    z-index: 3;
    top: 4em;
    left: 1em;
    padding: 1em;
    border-radius: .3em;
    background-color: #fff;
}

.messageContainer .text-link {
    margin-left: 1em;
    font-weight: bold;
}

@media only screen and (min-width: 1020px) {
    .info-container {
        left: 12.5%;
        right: 12.5%;
    }

    .messageContainer {
        left: 12.5%;
    }
}

@media only screen and (max-width: 400px) {
    .info-container .info {
        padding-right: 0;
    }

    .info-container .openingTimes {
        position: relative;
        bottom: auto;
        top: .5em;
        /* height: 2em; */
        max-height: 2em;
        margin-left: 0;
        transition: .2s max-height ease-out; 
    }

    .info-container .openingTimes.expanded {
        /* height: auto; */
        max-height: 300px;
    }

    .info-container .openingTimes>div,
    .info-container .openingTimes>span{
        padding-left: 0;
    }
}

.listContainer {
    display: grid;
    width: 100%;
    padding: 5em 1em;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr;
    object-fit: cover;
    /* overflow: hidden; */
}

.listContainer .spacer{
    width: 100%;
    height: 2em;
    grid-column: 1 / span 2;
}

.listContainer .nodata {
    text-align: center;
    font-weight: 900;
    padding: 2em;
    margin: 0 auto;
    max-width: 500px;
    margin-top: 2em;
    grid-column: 1 / span 2;
}

.listContainer .nodata .message {
    font-size: 1.3em;
    text-transform: uppercase;
}

.listContainer .nodata img {
    
    width: 80%;
    height: auto;
    margin-top: 2em;
}

.listItem {
    position: relative;
    background-color: #000;
    margin-bottom: 3em;
    border-radius: .6em;
    height: 0;
    padding-bottom: 75%;
    /* border: 1px solid black; */
    /* box-shadow: 0px 0px 15px rgba(0,0,0,.5); */
    box-shadow: 0px 0px 25px rgba(0,0,0,.3);
}

.listItem.white,
.listItem.logo-false {
    background-color: #fff;
}

.listItem .inner {
    position: absolute;
    top: 1em;
    bottom: 1em;
    left: 1em;
    right: 1em;
    width: auto;
    height: auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: .5em;
    /* margin: 1em; */
}

.listItem.logo-false .inner {
    background-size: 80%;
    opacity: .5;
}
/* 
.listItem .inner a{
    width: 100%;
    height: 100%;
} */

.listItem.fill .inner {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.listItem.fill .inner {
    background-size: cover;
}

.listItem h2 {
    position: absolute;
    top: 100%;
    font-size: 1em;
    margin: 0;
    padding: .5em;
}

.listItem.fill h2 {
    margin-top: 0;
}

.listItem .icons {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 0;
    color: white;
    align-items: center;
    justify-content: space-between;
    left: 0em;
    padding: 1em;
    padding-right: .8em;
    background-color: transparent;
    /* background-color: white; */
    text-shadow: 0px 0px 3px rgba(0,0,0,.7);
    /* box-shadow: inset 0px 5px 5px rgba(0,0,0,.5); */
    border-radius: 0 0 .5em .5em;
    /* background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255, 1)) */
}

.listItem .icons i::before{
    margin-left: .5em;
    /* display: inline-block; */
    /* padding: 0 .2em; */
}

.listItem .favourite {
    cursor: pointer;
}

.listItem.logo-false .icons,
.listItem.white .icons {
    color: black;
    text-shadow: 0px 0px 3px rgba(255,255,255,.7);
}

.listItem.fav-true .favourite {
    color: red;
}

@media only screen and (min-width: 1020px) {
    .listContainer {
        width: 75%;
        padding-left: 0;
        padding-right: 0;
        /* grid-template-columns: 100%; */
    }
}

@media only screen and (max-width: 800px) {
    .listContainer {
        grid-template-columns: 100%;
    }

    .listContainer .nodata {
        grid-column: 1 / span 1;
    }

    .listContainer .spacer{
        width: 100%;
        height: 2em;
        grid-column: 1 / span 1;
    }
}
button,
.button{
    display: inline-block;
    background-color: black;
    color: white !important;
    font-size: 1.3em;
    border: none;
    /* border-radius: 5em; */
    padding: 2em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    /* min-width: 5em; */
}

.small-btn{
    display: inline-block;
    background-color: black;
    color: white;
    width: auto;
    min-width: 6em;
    text-align: center;
    font-size: 1em;
    border: none;
    /* border-radius: 5em; */
    padding: 1em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
}

.text-link {
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
}

.errorMessages {
    display: inline-block;
    padding: 1em;
    background-color: #f2f2f2;
    border-radius: .2em;
    margin-top: 1em;
    /* vertical-align: top; */
}

.errorMessages .error {
    color: red !important;
}

.filterSearchContainer {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    height: 2.5em;
    color: #fff;
    /* transform: translateY(-100%); */
}

.filterSearchContainer h3 {
    text-transform: uppercase;
    margin: 0;
    font-size: 1em;
    margin-bottom: .5em;
}

.filterSearchContainer input[type="checkbox"] {
    /* TODO */
    font-size: 1.5em;
    margin: 0;
}

.searchBar {
    display: flex;
    position: absolute;
    z-index: 2;
    width: 100%;
    background-color: #000;
}

.searchBar .search {
    position: relative;
    padding: .3em;
    padding-left: 1em;
    flex: 4 1;
    /* text-align: center; */
}

.search input {
    width: 100%;
    max-width: 500px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    padding: .3em 1em;
}

.searchBar .toggleFilters {
    flex: 0 1;
    color: #fff;
    font-size: 1em;
    margin-top: .5em;
    margin-right: 1em;
}

.filterOptions {
    /* display: flex; */
    /* flex-direction: column; */
    width: 100%;
    max-height: calc(100vh - 2.2em);
    z-index: 1;
    padding-top: 3.4em;
    /* grid-gap: 1em;
    grid-template-columns: 1fr 1fr; */
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    background-color: rgba(255,255,255,1);
    background-color: #f2f2f2;
    color: #000;
    /* opacity: 0; */
    transition: all .3s ease-in-out;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.filterOptions.visible {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    box-shadow: 0px 0px 150px rgba(0,0,0,.8);
}

.filterOptions>div{
    flex: 1 1;
    padding: 1em;
    /* padding-bottom: 5em; */
}

.filterOptions .buttons {
    padding-top: 0;
    text-align: right;
}

.filterOptions .small-btn {
    margin: 1em;
}

/* .filterOptions .industryContainer {
    padding-left: 0;
    padding-right: 2em;
} */

.industryContainer .selectIndustries {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: .5em;
}

.industryContainer label {
    display: block;
    /* margin-bottom: .5em; */
    text-transform: capitalize;
}

.clickableOptions {
    display: grid;
    width: 100%;
    max-width: 25em;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;
    /* padding-right: 1em; */
    /* padding: 0 1em; */
    /* grid-template-rows: 6em; */
    /* margin-bottom: 2em; */
    /* margin-right: 2em; */
}

.clickableOptions>div {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border: 1px solid black;
    border-radius: .3em;
    text-align: center
}

.clickableOptions .inner {
    display: flex;
    position: absolute;
    top: 1em;
    bottom: 1em;
    left: 1em;
    right: 1em;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
}

.clickableOptions i::before {
    font-size: 2em;
    margin: 0;
}

.clickableOptions>div.active {
    background-color: #000;
    color: #fff;
}

.clickableOptions i {
    flex: 1 1;
    display: block;
    font-size: 2em;
}

.clickableOptions span {
    font-size: .8em;
    line-height: 1;
}

/* TODO: slider styles */
.sliderContainer {
    display: grid;
    grid-template-columns: 2em auto 4em;
    justify-items: center;
    align-items: center;
    width: 100%;
    max-width: 30em;
    /* background: gray; */
    /* position: relative; */
}

.sliderContainer .slider {
    flex: 1 1;
    width: 100%;
}

.sliderContainer.disabled label {
    display: none;
}

.sliderContainer.disabled .slider {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none
}

/* custom range input */
input[type=range] {
    -webkit-appearance: none;
    margin: 1em 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: .5em;
    cursor: pointer;
    animate: 0.2s;
    background: #000;
    border: none;
  }
  input[type=range]::-webkit-slider-thumb {
    border: none;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    background: #000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -.75em;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #000;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: .5em;
    cursor: pointer;
    animate: 0.2s;
    background: #000;
    border: none;
  }
  input[type=range]::-moz-range-thumb {
    border: none;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    background: #000;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: .5em;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #000;
    border: none;
    border-radius: 0;
  }
  input[type=range]::-ms-fill-upper {
    background: #000;
    border: none;
    border-radius: 0;
  }
  input[type=range]::-ms-thumb {
    border: none;
    height: 2em;
    width: 2em;
    border-radius: 0;
    background: #000;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #000;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #000;
  }


.toggleView {
    position: fixed;
    z-index: 99;
    right: .8em;
    top: 4em;
    background-color: white;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    line-height: 3em;
    text-align: left;
    background-color: #000;
    color: #fff;
    border: 1px solid white;
    text-align: center;
    /* box-shadow: 0px 0px 20px rgba(255,255,255,.8); */
}

.toggleView i::before {
    margin: 0;
}

.toggleView img {
    position: absolute;
    max-width: 2em;
    max-height: 2em;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
}

.navBar {
    display: flex;
    position: fixed;
    z-index: 2;
    width: 100%;
    padding: .6em;
    padding-bottom: .5em;
    background-color: #000;
    color: #fff;
    bottom: 0;
}

.navBar .tab {
    flex: 1 1;
    text-align: center;
}

.navBar .tab.active {
    color: #edfb37;
}

.navBar i::before{
    font-size: 1.5em;
}

.navBar .tab img {
    height: 1.5em;
}

.navBar .tab.active img.pin_normal,
.navBar .tab img.pin_active {
    display: none;
}

.navBar .tab.active img.pin_active {
    display: inline-block;
}

.promptContainer {
    position: fixed;
    max-width: 450px;
    margin: 0 auto;
    top: 4em;
    padding: 2em;
    border-radius: .5em;
    box-shadow: 0px 0px 50px rgba(0,0,0,.8);
    background-color: #fff;
    text-align: center;
}

.promptContainer.closed {
    display: none;
}

.promptContainer h2 {
    margin: 0;
    /* margin-bottom: 1em; */
    font-size: 1.5em;
    font-weight: 700;
}

.promptContainer .promptContent {
    margin-top: 1em;
    margin-bottom: 2em;
}

.promptContainer .buttons {
    display: flex;
    /* margin-top: 1em; */
    /* align-content: space-between; */
    justify-content: space-around;
}

@media only screen and (min-width: 1020px) {
    .searchBar{
        padding: 0 12.5%;
    }

    .searchBar .search { 
        padding-left: 0;
    }

    .navBar {
        padding-left: 12.5%;
        padding-right: 12.5%;
    }

    .navBar>div>i{
        margin-left: 1em;
    }

    .filterOptions {
        width: 75%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 850px) {
    .industryContainer .selectIndustries {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 500px) {
    .industryContainer .selectIndustries {
        grid-template-columns: 1fr;
    }
    
    .clickableOptions i::before {
        font-size: 1.5em;
    }

    .promptContainer {
        left: 1em;
        right: 1em;
    }
}

@media only screen and (max-width: 350px) {

    .clickableOptions {
        grid-template-columns: 1fr 1fr;
    }

    .clickableOptions i::before {
        font-size: 2em;
        line-height: 1.5;
    }
}
.businessProfile {
    background-color: #f7f7f7;
    height: 100%;
    /* padding-bottom: 2.5em; */
    /* display: block; */
    /* padding-bottom: 3em; */
}

/* .businessProfile .topSection, .businessProfile .bottomSection {
    position: relative;
    flex: 1;
    width: 100%;
    height: 50vh;
    overflow: hidden;
} */

.businessProfile .imageContainer {
    height: 40%;
    width: 100%;
    background-color: #000;
}

.businessProfile.white .imageContainer {
    background-color: #fff;
}

.businessProfile .profileImage {
    width: 100%;
    height: 100%;
    /* margin: 1em; */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.businessProfile.fill .profileImage {
    background-size: cover;
}

.businessProfile.logo-false .imageContainer {
    background-color: #fff;
}

.businessProfile.logo-false .profileImage {
    background-size: cover;
}

.businessProfile .icons {
    flex: 0 1;
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1em;
    padding: 1em;
    color: #000;
    /* text-shadow: 0px 0px 3px rgba(255,255,255,.7); */
    background-color: white;
    /* padding-bottom: 1em; */
    /* background-color: rgba(255,255,255,.5); */
    /* box-shadow: 0px 0px 15px rgba(0,0,0,.5); */
}

.businessProfile.black .icons {
    color: white;
    /* text-shadow: 0px 0px 3px rgba(0,0,0,.7); */
    background-color: black;
}

.businessProfile .icons i::before {
    margin-left: 0;
    margin-right: .5em;
}

.businessProfile.fav-true .favourite {
    cursor: pointer;
    color: red;
}

.businessProfile .bottomSection {
    flex-grow: 1;
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    background-color: #f7f7f7;
    /* height: 50%; */
    /* overflow: auto; */
    width: 100%;
    /* -webkit-overflow-scrolling: touch; */
    padding-bottom: 3em;
}

.businessProfile .details {
    /* display: flex; */
    flex: 0 1;
    padding: 1em;
}

.businessProfile .details .address {
    flex: 1 1;
    max-width: 70%;
}

.businessProfile .details .name {
    /* font-weight: bold; */
}

.businessProfile .details .openingTimes {
    position: absolute;
    /* position: relative; */
    right: 1em;
    margin-left: 1em;
    font-size: .9em;
    /* padding: .5em;
    padding-top: 0; */
    bottom: calc(100% - 5em);
    top: 3em;
    transition: .2s bottom ease-out; 
    
    overflow: hidden;
}

.businessProfile .details .openingTimes>span {
    padding: .5em;
    background-color: rgba(255,255,255,0);
    transition: .1s background-color linear;
    transition-delay: .2s;
    cursor: pointer;
}

.businessProfile .details .openingTimes.expanded>span {
    background-color: rgba(255,255,255,.9);
    transition-delay: 0s;
}

.businessProfile .details .openingTimes.expanded {
    
    transition-delay: 0s;
    bottom: 1em;
    /* max-height: 500px; */
    overflow: auto;
}

.businessProfile .details .openingTimes i {
    height: 1.2em;
    line-height: 1.2;
    vertical-align: middle;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: .1s transform linear;
}

.businessProfile .details .openingTimes.expanded i {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

.businessProfile .details .openingTimes>div {
    left: 0;
    right: 0;
    padding: .5em;
    padding-top: 0;
    background-color: rgba(255,255,255,0);
    transition: 0s background-color linear;
    transition-delay: .3s;
}

.businessProfile .details .openingTimes.expanded>div {
    background-color: rgba(255,255,255,.9);
    transition-delay: 0s;
}

.businessProfile .details .openingTimes.expanded>div {
    /* max-height: 500px;
    overflow: auto; */
}

.businessProfile .details .openingTimes .day {
    
}

.businessProfile .details .openingTimes .day h4 {
    margin-bottom: 0;
    margin-top: .8em;
}

.businessProfile .details .openingTimes .day:first-of-type h4 {
    margin-top: 0;
}

.businessProfile .details .openingTimes .hide {
    display: none;
}

.businessProfile .quote {
    padding: 0 1em;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: 900;
    text-align: center;
    /* overflow: auto; */
}

.businessProfile .quote .mark {
    font-size: 2em;
    padding: .1em;
    font-weight: bold;
    /* line-height: 1; */
}

.businessProfile .quote .text {
    padding: 0 1em;
}

@media only screen and (min-width: 800px) {
    .businessProfile .profileImage {
        width: 50%;
        margin: 0 auto
    }

    .bottomSection .icons {
        padding-left: 12.5%;
        padding-right: 12.5%;
    }

    .bottomSection .details {
        width: 75%;
        padding-left: 0;
        padding-right: 0;
        align-self: center;
    }

    .bottomSection .quote {
        margin: 0 12.5%;
        padding-left: 0;
        padding-right: 0;
    }
}
.infoContainer {
    width: 100%;
    padding: 3em;
    padding-bottom: 5em;
    font-size: 1.5em;
}

.infoContainer h1,
.infoContainer h2,
.infoContainer h3 {
    margin: 0;
    margin-bottom: 1em;
}

.infoContainer h1 {
    font-weight: 900;
}

.infoContainer h2{
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: .5em;
}

.infoContainer h3 {
    font-size: 1.5em;
}

.infoContainer p {
    margin: 0;
}

.infoContainer .faq {
    margin-bottom: 2em;
}

@media only screen and (max-width: 600px) {
    #FAQ {
        padding: 1em;
        padding-bottom: 3em;
        font-size: 1em;
    }
}

.loginRegister{
    padding: 1em;
    padding-bottom: 5em;
}

.loginRegister h2 {
    text-align: center;
}

.loginRegister .buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2em;
}

.loginRegister .button {
    min-width: 10em;
    margin: .5em;
}

.weekdays #alwaysOpen {
    margin-top: 1em;
}

@media only screen and (max-width: 500px) {
    .loginRegister .buttons {
        flex-direction: column;
        align-items: center;
    }
}
#startScreen {
    padding: 2em;
    padding-bottom: 5em;
    text-align: center;
}

#welcomeMessage {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 1em;
}

#welcomeMessage h2 {
    margin: 0;
    font-size: 3.5em;
}

#welcomeMessage h1 {
    text-transform: uppercase;
    font-weight: 100;
    font-size: 1.7em;
    margin: 0 auto;
}

#welcomeMessage p {
    font-weight: bold;
    line-height: 1.4;
    /* max-width: 500px; */
    margin: 0 auto;
}

#welcomeMessage p>span {
    display: block;
}

#welcomeMessage img {
    max-width: 100%;
    margin: 2em auto;
}

.featuredBusiness,
.newBusinesses {
    width: 100%;
    margin-top: 3.5em;
    /* padding: 2em; */
}

.featuredBusiness h3,
.newBusinesses h3 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2.3em;
}

.newBusinesses .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    width: 100%;
}

.featuredBusiness .listItem .icons,
.newBusinesses .listItem .icons {
    display: none;
}

.featuredBusiness .listItem h2,
.newBusinesses .listItem h2 {
    width: 100%;
    text-align: center;
}

/* @media only screen and (max-width: 1020px) {
    .newBusinesses .grid-col-3{
        grid-template-columns: 1fr 1fr;
    }
} */

/* @media only screen and (max-width: 600px) {
    .newBusinesses .grid-col-3{
        grid-template-columns: 1fr;
    }
} */

@media only screen and (max-width: 400px) {
    #startScreen {
        padding: 1em;
        padding-bottom: 5em;
    }

    .featuredBusiness h3,
    .newBusinesses h3 {
        font-size: 1.8em;
    }

    .newBusinesses .listItem h2 {
        font-size: .8em;
    }
}
