* {
    font-family: 'Montserrat';
    box-sizing: border-box;
}

*:focus { 
    outline: none;
}

body {
    /* background-color: #000000; */
}

body, html, #root{
    position: relative;
    width: 100%;
    height: 100%;
}

.app {
    background-color: #fff;
    position: relative;
    padding-bottom: 2.7em;
}

a, a:visited{
    text-decoration: none;
    color: inherit;
}

h2{
    font-weight: 900;
    text-transform: uppercase;
    font-size: 3em;
    margin-bottom: .5em;
}

.subline{
    text-transform: uppercase;
    font-size: 1.5em;
}



.shareButtons {
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-bottom: 3em;
    /* margin: .5em; */
}

.shareButtons>div{
    /* line-height: 2; */
    margin-top: 1em;
}

.shareButtons .share-button {
    display: inline-block;
    margin: .2em;
    margin-bottom: 1em;
    filter: grayscale(100%);
}

.app{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
}

/* .navBar{
    z-index: 2;
    position: fixed;
    width: 100%;
    color:black;
    text-align: right;
    padding: 1em;
    line-height: 1;
}

.navBar .textlink {
    margin-left: 1em;
    cursor: pointer;
}

.navBar .imprint {
    float: left;
}

.navBar a:hover,
.navBar .textlink:hover {
    font-size: bold!important;
    text-transform: underline!important;
}

.navBar .logo{
    float: left;
}

.navBar a, .navBar .links{
    display: inline-block;
}

.navBar .links a{
    cursor: pointer;
}

.navBar a.active{
    font-weight: bold;
} */

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    width: 100vw;
    /* padding-bottom: 3em; */
    /* padding-top: 3.2em; */
    /* overflow: auto; */
    /* background-color: red; */
}

.container.margin-top {
    margin-top: 5em;
}

.verifyMessage {
    width: 20em;
    height: 100%;
    margin: 5em auto;
    text-align: center;
}

.verifyMessage a {
    text-decoration: underline;
    font-weight: bold;
}

.verifyMessage img {
    height: 65%;
}

#signup_woman img{
    max-height: 50vh;
    width: auto;
}

#signup-intro{
    display: inline-flex;
    width: auto;
    height: 100vh;
    text-align: center;
}

#signup-intro .illustration {
    flex: 3;
    max-width: 70vh;
    text-align: center;
}

.illustration>img{
    width: 100%;
    height: auto;
    margin: 0 auto;
}

#signup-intro .select-mode {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

#signup-intro .select-mode button,
#signup-intro .select-mode .button{
    /* margin: .5em; */
    width: 12em;
    /* height: 5.3em; */
}

#signup-intro h2{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 1em;
}

#signup-intro .subline{
    text-align: center;
}

#signup-intro .shareButtons>div:first-child {
    margin-top: 2em;
}

.signup-message {
    text-align: center;
}

.signup-message h2{
    margin-bottom: 0;
}

.signup-message p {
    margin-top: 0;
    font-size: 1.5em
}

.progressBar{
    position: fixed;
    width: 100%;
    height: 1em;
    /* background-color: gray; */
}

.progress{
    position: relative;
    height: .3em;
    width: 3em;
    min-width: 5px;
    background-color: gray;
    border-radius: 0px 5px 5px 0px;
    transition: width .2s cubic-bezier(0.215, 0.610, 0.355, 1);
}

form{
    width: 100%;
    /* max-width: 40em; */
    padding: 2em;
    padding-bottom: 5em;
    padding-left: 5em;
    padding-right: 5em;
}

.inputContainer{
    position: relative;
    /* background: green; */
    padding-top: 1em;
    margin-bottom: 5em;
}

.inputContainer.checkbox{
    margin-bottom: 3em;
}

.inputContainer>label{
    /* display: flex;
    flex-direction: row;
    align-items: flex-end; */
    font-size: 1em;
    width: 100%;
    /* display: block; */
}

.private .inputContainer>label{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* justify-content: center; */
}

.labelText { 
    display: block;
    flex: 1;
    text-transform: uppercase;
    font-weight: bold;
}

.checkbox .labelText{
    display: inline-block;
}

.inputContainer textarea{
    display: block;
    border: 1px solid black;
    resize: none;
    width: 50%;
    height: 8em;
    margin-top: .5em;
    font-size: 1em;
    padding: 1em;
    /* margin-bottom: 3em; */
}

.inputContainer textarea.disabled{
    display: none;
}

.inputContainer label.file{
    /* flex: 0; */
    display: block;
    margin-bottom: 3em;
    /* margin-top: 2em; */
    width: 15em;
    height: 10em;
    /* height: 0; */
    /* background-color: black; */
    border-radius: 50%;
    margin: 0 auto;
    overflow: visible;
    cursor: pointer;
}

label.file .labelText{
    /* flex: 0; */
    position: absolute;
    top: -2em;
    left: 0%;
    width: 100%;
    /* border: 1px solid black; */
    padding: .5em 1em;
    cursor: pointer;
    text-align: center;
    /* width: 30em; */
}

label.checkboxLabel {
    display: inline;
}

#logo-img {
    background-color: white;
}

.bg-black #logo-img {
    background-color: black;
}

#logo-img .logo-container {
    width: 15em;
    height: 10em;
    /* background-color: black; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid black;
    /* border-radius: 50%; */
}

.display-fit #logo-img .logo-container {
    background-size: contain;
}

.logoGroup {
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
    padding-top: 3em;
    margin-bottom: 3em;
}

.logoOptions {
    margin: 0 2em;
}

.logoOptions .inputContainer {
    margin-bottom: 1em;
}

.logoOptions .radio-div {
    margin-right: 2em;
}

label .fileName {
    position: absolute;
    /* bottom: -2em; */
    width: 100%;
    left: 0%;
    text-align: center;
    font-weight: bold;
}

.inputContainer input{
    display: block;
    flex: 1;
    width: 50%;
    font-size: 1em;
    font-weight: bold;
    padding-top: 1em;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    /* margin-bottom: 3em; */
}

.inputContainer.email,
.inputContainer.contactPerson {
    display: inline-block;
    width: calc(50% - 1em);
}

.inputContainer.email{
    margin-right: 2em;
}

.inputContainer.email input,
.inputContainer.contactPerson input{
    width: 100%;
} 

.inputContainer input:focus{
    border-bottom-width: 2px;
}

input[type="file"] {
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

input[type="checkbox"] {
    display: inline;
    width: 1em!important;
}

.inputContainer input[type="radio"] {
    width: 1em;
}

.inputContainer.hasError input{
    border-color: red;
}

.inputContainer .errorMessage{
    display: none;
    position: absolute;
    color: red;
    top: 100%;
    padding-top: .3em;
}

.inputContainer .errorMessage.info{
    display: block;
    color: darkgoldenrod;
}

.inputContainer.hasError .errorMessage{
    display: block;
}

.radio-group {
    display: flex;
    align-items: flex-end;
    width: 100%;
    text-align: center;
}

.radio-div{
    /* text-align: center; */
    /* display: inline-block; */
    margin-right: 1em;
}

.radio-div input{
    display: inline-block!important;
    /* margin: 0 auto;
    text-align: center; */
}

.radio-div img{
    max-width: 100%;
    padding: 1em;
}

.dropdown{
    /* margin: 2em 0; */
    margin-top: .5em;
    padding: .5em 1em;
    /* border: 1px solid black; */
    background-color: black;
    color: white;
    margin-bottom: 3em;
    width: 50%;
}

.dropbtn{
    cursor: pointer;
}

.dropbtn i{
    width: 1em;
    height: 1em;
    margin-left: 1em;
    /* padding: 1em; */
    /* background: red; */
    transition: .1s linear;
    vertical-align: middle;
}

.closed .dropbtn i{
    transform: rotate(-90deg)
}

.dropbtn>*{
    display: inline-block;
    user-select: none;
}

.collapsable{
    transition: max-height 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    overflow: hidden;
}

.open .collapsable{
    max-height: 300px;
    /* background-color: green; */
}

.closed .collapsable{
    max-height: 0;
    /* background-color: red; */
}

.dropdown .search{
    position: relative;
}

.dropdown .search>input {
    width: 100%;
    /* border: none; */
    font-size: 1em;
    font-weight: normal;
    border: none;
    padding: .2em;
    padding-right: 2em;
    margin-bottom: 0;
    background-color: #eee;
    border-radius: 5px
}

.dropdown .search>i{
    position: absolute;
    opacity: 1;
    top: 0;
    right: 0;
    font-size: 1.6em;
    cursor: pointer;
    transition: opacity .2s linear;
}

.dropdown .search>input:placeholder-shown~i{
    opacity: 0;
    pointer-events: none;
}

.dropdown .collapsable>div{
    margin: .5em 0;
}

.dropdown .collapsable>div:not(.selectedOption):hover{
    background: lightgray;
}

/* .openOptions {
    border-bottom: 1px solid black;
    margin-top: 3em;
    padding-bottom: 1em;
}

.daygroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    margin-top: 1em;
    margin-bottom: 1em;
}

.daygroup.closed{
    align-items: center;
} */

/* .timeOptions{
    flex: 1;
    text-align: left;
}

.closed .timeOptions{
    font-weight: bold;
    text-transform: uppercase;
}

.daygroup .textLink{
    margin-top: 1em;
    text-decoration: underline;
}

.addOption{
    text-transform: uppercase;
    text-align: center;
    text-decoration: underline;
} */

.weekdayContainer {
    position: relative;
    /* display: flex; */
    align-items: center;
    margin-top: 2em;
}

.weekday{
    display: inline-block;
    width: 3em;
    height: 3em;
    font-size: .8em;
    margin-right: 1em;
    background-color: gray;
    color: white;
    border-radius: 50%;
    line-height: 3em;
    text-align: center;
    cursor: pointer;
}

.weekday.selected{
    background-color: black;
}

.periods{
    display: inline-block;
    vertical-align: text-top;
}

.period {
    margin-bottom: 1em;
}

.addPeriod,
.removePeriod {
    display: inline-block;
    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 1em;
    font-size: 1.5em;
    /* font-weight: bold; */
    /* background-color: black;
    color: white; */
    /* border: 1px solid black; */
    cursor: pointer;
    border-radius: 50%;
}

.removePeriod {
    transform: rotate(45deg)
}

/* .period::after {
    content: " - ";
    padding: 0 1em;
} */

.period input {
    /* width: 5em!important; */
}

.period.hidden{
    display: none;
}

.period>*{
    display: inline-block;
    margin: 0!important;
}

.period input{
    width: auto !important;
}

.weekdayContainer .errorMessage {
    display: block;
}

.times input {
    width: auto;
    padding: 0;
    margin-bottom: 0;
    display: inline-block;
}

.times .timeInputs i{
    font-size: 1em;
    padding: .5em;
}

.illufield {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
    margin-bottom: 10em;
}

.illufield.signup{
    margin-bottom: 1em;
}

.illufield>.inputContainer {
    flex: 2;
    margin-bottom: 0!important;
    max-width: 50vw;
}

.illufield .illustration {
    flex: 1;
    text-align: center;
}

.illufield #signup_woman{
    flex: 2;
}

.illufield img {
    max-height: 15em;
    width: auto;
    margin: 0 auto;
}

.illufield #signup_woman img{
    max-height: 30em;
}

.illufield input,
.illufield textarea {
    width: 100%;
}

.errorMessages>div {
    color: red;
}

.errorMessages>div:first-child {
    color: black;
}

.sdg-grid {
    display: grid;
    /* grid-auto-columns: 1fr; */
    /* grid-auto-flow: column; */
    grid-template-columns: repeat(9, 1fr);
    /* grid-auto-rows: min-content; */
    grid-gap: 1em;
}

.sdg-grid label {
    display: block;
    justify-self: stretch;
    align-self: stretch;
    cursor: pointer;
    /* grid-column: 1fr; */
}

.sdg-grid label.off{
    filter: grayscale(100%);
}

.sdg-grid label span {
    display: block;
    font-size: .8em;
}

.sdg-grid img {
    padding: 0;
    max-width: 100%;
}

/* .times .day{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: .5em;
}

.times label{
    display: inline-block;
    text-transform: capitalize;
    line-height: 2
}

.times>label{
    margin-bottom: 1em
}

.times .timeInputs{
    /* flex: 1; */
    /* text-align: right;
} */



.login .inputContainer.password {
    margin-bottom: 0;
}

.login a{
    display: block;
    width: 50%;
    text-align: right;
    text-decoration: underline;
    margin-top: 1em;
    margin-bottom: 5em;
}

.forgot-password button {
    display: block;
}

.authErrorMessage{
    position: fixed;
    z-index: 3;
    top: 50%;
    margin: 0 auto;
    background-color: white;
    padding: 2em;
    box-shadow: 0px 1px 15px rgba(0,0,0,.7);
    border-radius: 10px;
}

.bottom-message {
    position: fixed;
    z-index: 3;
    width: 100%;
    /* min-height: 3em; */
    bottom: 0;
    background-color: black;
    color: white;
    padding: 1em;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    /* line-height: 3em; */
}

.map{
    width: 100%;
    height: 100%;
}

.mapContainer{
    position: relative;
    width: 100vw;
    height: 100vh;
    background: black;
    top: 0;
    left: 0;
}

.marker{
    position: relative;
    width:2em;
    height: 2em;
    background-color: white;
}

.marker img{
    max-width: 100%;
    max-height: 100%;
}

.dashboard {
    /* text-align: center; */
    width: 100%;
}

.dashboard h3{
    margin-top: 0;
    /* margin-bottom: 3em; */
}

.dashboard .settings {
    display: grid;
    width: 100%;
    max-width: 40em;
    grid-template-columns: 33% auto 33%;
    grid-row-gap: 2em;
    grid-column-gap: 1em;
    margin-top: 3em;
    /* display: flex; */
    /* justify-items: center;
    align-items: center; */
} 

.dashboard .property {
    grid-column-start: 1;
    text-align: right;
}

.dashboard .value{
    position: relative;
    grid-column-start: 2;
    font-weight: bold;
}

.dashboard .link{
    grid-column-start: 3;
    grid-column-end: 3;
}

.dashboard .option{
    text-align: center;
}

.dashboard .value input {
    font-size: 1em;
    height: 1em;
    padding-top: 0;
}

.dashboard .value .inputContainer {
    /* display: inline; */
    /* flex: 3; */
    width: 100%;
    margin: 0;
    padding: 0;
    /* width: 15em; */
}

/* .dashboard .value .inputContainer,
.dashboard .value label,
.dashboard .value input{
    display: inline;
} */

.dashboard .text-link {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    margin-left: 1em;
    margin-right: 1em;
    /* margin-left: 2em; */
    /* margin-top: 1em; */
}

.email-handler-message {
    margin-top: 3em;
    padding: 1em;
    
    background-color: #eee;
}

.reset-info {
    margin-top: 3em;
}

form.reset-pw{
    width: 50%;
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    .sdg-grid {
        grid-template-columns: repeat(6, 1fr);
    }
}


@media only screen and (max-width: 800px) {
    form{
        padding-bottom: 5em;
        padding-left: 1em;
        padding-right: 1em;
    }

    .progressBar {
        display: none;
    }
    
    .inputContainer{
        margin-bottom: 3em;
    }
    
    .inputContainer.checkbox{
        margin-bottom: 2em;
    }
    
    .checkbox .labelText{
        display: inline-block;
    }
    
    .inputContainer textarea,
    .inputContainer input{
        width: 100%;
    }

    .period input{
        width: auto;
    }

    .illufield {
        display: block;
        margin-bottom: 3em;
    }

    .illufield.times{
        display: flex;
        flex-direction: column-reverse;
    }
    
    .illufield>.inputContainer {
        flex: 1;
        margin-bottom: 0!important;
        max-width: 100%;
        width: 100%;
    }

    .dropdown{
        width: 100%;
    }

    .inputContainer.email,
    .inputContainer.contactPerson {
        display: block;
        width: 100%;
    }

    /* .inputContainer label.file .labelText{
        margin-top: 3em;
    }

    .inputContainer label.file{
        display: block;
        margin-bottom: 5em;
        margin-top: 3em;
    } */

    input[type="file"] {
        width: 0.1px!important;
    }

    .radio-div img{
        /* max-width: 100%; */
        padding: 0;
    }

    .daygroup{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 1em;
        margin-bottom: 3em;
    }

    .timeOptions{
        margin-bottom: 1em;
        text-align: center;
    }

    .addOption{
        margin-bottom: 3em
    }

    .select-mode {
        text-align: center;
    }

    .bottom-message {
        font-size: .7em;
        font-weight: normal;
        padding: 1em .5em;
        /* line-height: 3em; */
    }

    .sdg-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    

    #signup-intro{
        display: flex;
    }

    #signup-intro .select-mode {
        /* display: inline-block; */
        flex-direction: column;
        align-items: center;
    }

    #signup-intro .select-mode .button:first-child{
        margin-bottom: .5em;
    }
}

@media only screen and (max-width: 600px) {
    .sdg-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 400px) {
    .logoGroup {
        display: block;
    }

    .sdg-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}