.infoContainer {
    width: 100%;
    padding: 3em;
    padding-bottom: 5em;
    font-size: 1.5em;
}

.infoContainer h1,
.infoContainer h2,
.infoContainer h3 {
    margin: 0;
    margin-bottom: 1em;
}

.infoContainer h1 {
    font-weight: 900;
}

.infoContainer h2{
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: .5em;
}

.infoContainer h3 {
    font-size: 1.5em;
}

.infoContainer p {
    margin: 0;
}

.infoContainer .faq {
    margin-bottom: 2em;
}

@media only screen and (max-width: 600px) {
    #FAQ {
        padding: 1em;
        padding-bottom: 3em;
        font-size: 1em;
    }
}