#settingsContainer {
    position: relative;
    width: 100%;
    max-width: 400px;
    padding: 1em;
    margin: 0 auto;
    /* align-items: center; */
}

.settingsSection h3 {
    text-transform: uppercase;
    font-size: 1em;
    margin-bottom: .3em;
    margin-top: 2em;
}

#settingsContainer .messages {
    margin-top: 2em;
    text-align: center;
    padding: 1em;
    background-color: #f2f2f2;
    border-radius: .3em;
}

/* .settingsSection {
    display: flex;
    flex-direction: 
} */

.settingsSection a,
.settingsSection .link {
    display: block;
    width: 100%;
    max-width: 400px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: .8em;
    margin-bottom: .3em;
    border-radius: .3em;
    font-weight: bold;
    font-size: .8em;
    cursor: pointer;
}

.singleSetting {
    width: 100%;
    padding: 2em;
}

.singleSetting .errorMessage {
    margin-top: 1em;
}

.editProfile {
    position: relative;
}

.editProfile h1 {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 0;
}

.editProfile .closeBtn {
    position: absolute;
    right: 1em;
    top: 1em;

}

