.info-container {
    display: flex;
    /* display: none; */
    position: absolute;
    z-index: 3;
    left: 1em;
    right: 1em;
    bottom: 4em;
    padding: 1em;
    background-color: #fff;
    border-radius: .5em;
}

.info-container .close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2em;
    height: 1em;
    width: 1em;
    text-align: center;
    line-height: 1em;
    font-weight: bold;
    transform: rotate(45deg);
    /* background-color: grey; */
}

.info-container .info {
    padding-right: 6em;
    max-width: 100%;
}

.info-container h2 {
    font-size: 2em;
    margin: 0;
}

.info-container>div {
    flex: 1;
}

.info-container .logo {
    flex: 0;
    width: 12em;
    height: 9em;
    flex-basis: 12em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 1em;
}

.info-container .industry {
    font-size: .8em;
    margin-bottom: .5em;
}

.info-container .icons {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
}

.info-container .icons>div>* {
    display: inline-block;
}

.info-container .icons-right i {
    margin-left: .5em;
}

.info-container .openingTimes {
    position: absolute;
    /* position: relative; */
    right: 0;
    margin-left: 1em;
    font-size: .8em;
    /* padding: .5em;
    padding-top: 0; */
    bottom: calc(100% - 4em);
    top: 2em;
    transition: .2s bottom ease-out; 
    overflow: hidden;
}

.info-container .openingTimes.expanded {
    transition-delay: 0s;
    bottom: 3.5em;
    /* max-height: 500px; */
    overflow: auto;
}

.info-container .openingTimes>span {
    padding: .5em;
    background-color: rgba(255,255,255,0);
    transition: .1s background-color linear;
    transition-delay: .2s;
    cursor: pointer;
}

.info-container .openingTimes.expanded>span {
    background-color: rgba(255,255,255,.9);
    transition-delay: 0s;
}

.info-container .openingTimes i {
    height: 1.2em;
    line-height: 1.2;
    vertical-align: middle;
    transform: rotate(-90deg);
    transition: .3s transform linear;
}

.info-container .openingTimes.expanded i {
    transform: rotate(0);
}

.info-container .openingTimes>div {
    left: 0;
    right: 0;
    padding: .5em;
    padding-top: 0;
    background-color: rgba(255,255,255,0);
    transition: 0s background-color linear;
    transition-delay: .3s;
}

.info-container .openingTimes.expanded>div {
    background-color: rgba(255,255,255,.9);
    transition-delay: 0s;
}

.info-container .openingTimes .day h4 {
    margin-bottom: 0;
    margin-top: .8em;
}

.info-container .openingTimes .day:first-of-type h4 {
    margin-top: 0;
}

.info-container .openingTimes .hide {
    display: none;
}

.messageContainer {
    position: absolute;
    max-width: 500px;
    z-index: 3;
    top: 4em;
    left: 1em;
    padding: 1em;
    border-radius: .3em;
    background-color: #fff;
}

.messageContainer .text-link {
    margin-left: 1em;
    font-weight: bold;
}

@media only screen and (min-width: 1020px) {
    .info-container {
        left: 12.5%;
        right: 12.5%;
    }

    .messageContainer {
        left: 12.5%;
    }
}

@media only screen and (max-width: 400px) {
    .info-container .info {
        padding-right: 0;
    }

    .info-container .openingTimes {
        position: relative;
        bottom: auto;
        top: .5em;
        /* height: 2em; */
        max-height: 2em;
        margin-left: 0;
        transition: .2s max-height ease-out; 
    }

    .info-container .openingTimes.expanded {
        /* height: auto; */
        max-height: 300px;
    }

    .info-container .openingTimes>div,
    .info-container .openingTimes>span{
        padding-left: 0;
    }
}
