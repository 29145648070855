	/*
  	Flaticon icon font: Flaticon
  	Creation date: 27/04/2019 19:59
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
/* margin-left: 20px; */
}

.flaticon-home:before { content: "\f100"; }
.flaticon-house:before { content: "\f101"; }
.flaticon-setup:before { content: "\f102"; }
.flaticon-gear:before { content: "\f103"; }
.flaticon-mail:before { content: "\f104"; }
.flaticon-internet:before { content: "\f105"; }
.flaticon-heart:before { content: "\f106"; }
.flaticon-heart-1:before { content: "\f107"; }
.flaticon-clock:before { content: "\f108"; }
.flaticon-hour:before { content: "\f109"; }
.flaticon-user:before { content: "\f10a"; }
.flaticon-supermarket:before { content: "\f10b"; }
.flaticon-website:before { content: "\f10c"; }
.flaticon-lock:before { content: "\f10d"; }
.flaticon-list:before { content: "\f10e"; }
.flaticon-left-arrow:before { content: "\f10f"; }
.flaticon-left-arrow-1:before { content: "\f110"; }
.flaticon-information-button:before { content: "\f111"; }
.flaticon-info:before { content: "\f112"; }