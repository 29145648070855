.listContainer {
    display: grid;
    width: 100%;
    padding: 5em 1em;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr;
    object-fit: cover;
    /* overflow: hidden; */
}

.listContainer .spacer{
    width: 100%;
    height: 2em;
    grid-column: 1 / span 2;
}

.listContainer .nodata {
    text-align: center;
    font-weight: 900;
    padding: 2em;
    margin: 0 auto;
    max-width: 500px;
    margin-top: 2em;
    grid-column: 1 / span 2;
}

.listContainer .nodata .message {
    font-size: 1.3em;
    text-transform: uppercase;
}

.listContainer .nodata img {
    
    width: 80%;
    height: auto;
    margin-top: 2em;
}

.listItem {
    position: relative;
    background-color: #000;
    margin-bottom: 3em;
    border-radius: .6em;
    height: 0;
    padding-bottom: 75%;
    /* border: 1px solid black; */
    /* box-shadow: 0px 0px 15px rgba(0,0,0,.5); */
    box-shadow: 0px 0px 25px rgba(0,0,0,.3);
}

.listItem.white,
.listItem.logo-false {
    background-color: #fff;
}

.listItem .inner {
    position: absolute;
    top: 1em;
    bottom: 1em;
    left: 1em;
    right: 1em;
    width: auto;
    height: auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: .5em;
    /* margin: 1em; */
}

.listItem.logo-false .inner {
    background-size: 80%;
    opacity: .5;
}
/* 
.listItem .inner a{
    width: 100%;
    height: 100%;
} */

.listItem.fill .inner {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.listItem.fill .inner {
    background-size: cover;
}

.listItem h2 {
    position: absolute;
    top: 100%;
    font-size: 1em;
    margin: 0;
    padding: .5em;
}

.listItem.fill h2 {
    margin-top: 0;
}

.listItem .icons {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 0;
    color: white;
    align-items: center;
    justify-content: space-between;
    left: 0em;
    padding: 1em;
    padding-right: .8em;
    background-color: transparent;
    /* background-color: white; */
    text-shadow: 0px 0px 3px rgba(0,0,0,.7);
    /* box-shadow: inset 0px 5px 5px rgba(0,0,0,.5); */
    border-radius: 0 0 .5em .5em;
    /* background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255, 1)) */
}

.listItem .icons i::before{
    margin-left: .5em;
    /* display: inline-block; */
    /* padding: 0 .2em; */
}

.listItem .favourite {
    cursor: pointer;
}

.listItem.logo-false .icons,
.listItem.white .icons {
    color: black;
    text-shadow: 0px 0px 3px rgba(255,255,255,.7);
}

.listItem.fav-true .favourite {
    color: red;
}

@media only screen and (min-width: 1020px) {
    .listContainer {
        width: 75%;
        padding-left: 0;
        padding-right: 0;
        /* grid-template-columns: 100%; */
    }
}

@media only screen and (max-width: 800px) {
    .listContainer {
        grid-template-columns: 100%;
    }

    .listContainer .nodata {
        grid-column: 1 / span 1;
    }

    .listContainer .spacer{
        width: 100%;
        height: 2em;
        grid-column: 1 / span 1;
    }
}