button,
.button{
    display: inline-block;
    background-color: black;
    color: white !important;
    font-size: 1.3em;
    border: none;
    /* border-radius: 5em; */
    padding: 2em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    /* min-width: 5em; */
}

.small-btn{
    display: inline-block;
    background-color: black;
    color: white;
    width: auto;
    min-width: 6em;
    text-align: center;
    font-size: 1em;
    border: none;
    /* border-radius: 5em; */
    padding: 1em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
}

.text-link {
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
}

.errorMessages {
    display: inline-block;
    padding: 1em;
    background-color: #f2f2f2;
    border-radius: .2em;
    margin-top: 1em;
    /* vertical-align: top; */
}

.errorMessages .error {
    color: red !important;
}

.filterSearchContainer {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    height: 2.5em;
    color: #fff;
    /* transform: translateY(-100%); */
}

.filterSearchContainer h3 {
    text-transform: uppercase;
    margin: 0;
    font-size: 1em;
    margin-bottom: .5em;
}

.filterSearchContainer input[type="checkbox"] {
    /* TODO */
    font-size: 1.5em;
    margin: 0;
}

.searchBar {
    display: flex;
    position: absolute;
    z-index: 2;
    width: 100%;
    background-color: #000;
}

.searchBar .search {
    position: relative;
    padding: .3em;
    padding-left: 1em;
    flex: 4;
    /* text-align: center; */
}

.search input {
    width: 100%;
    max-width: 500px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    padding: .3em 1em;
}

.searchBar .toggleFilters {
    flex: 0;
    color: #fff;
    font-size: 1em;
    margin-top: .5em;
    margin-right: 1em;
}

.filterOptions {
    /* display: flex; */
    /* flex-direction: column; */
    width: 100%;
    max-height: calc(100vh - 2.2em);
    z-index: 1;
    padding-top: 3.4em;
    /* grid-gap: 1em;
    grid-template-columns: 1fr 1fr; */
    transform: translateY(-100%);
    background-color: rgba(255,255,255,1);
    background-color: #f2f2f2;
    color: #000;
    /* opacity: 0; */
    transition: all .3s ease-in-out;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.filterOptions.visible {
    transform: translateY(0);
    opacity: 1;
    box-shadow: 0px 0px 150px rgba(0,0,0,.8);
}

.filterOptions>div{
    flex: 1;
    padding: 1em;
    /* padding-bottom: 5em; */
}

.filterOptions .buttons {
    padding-top: 0;
    text-align: right;
}

.filterOptions .small-btn {
    margin: 1em;
}

/* .filterOptions .industryContainer {
    padding-left: 0;
    padding-right: 2em;
} */

.industryContainer .selectIndustries {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: .5em;
}

.industryContainer label {
    display: block;
    /* margin-bottom: .5em; */
    text-transform: capitalize;
}

.clickableOptions {
    display: grid;
    width: 100%;
    max-width: 25em;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;
    /* padding-right: 1em; */
    /* padding: 0 1em; */
    /* grid-template-rows: 6em; */
    /* margin-bottom: 2em; */
    /* margin-right: 2em; */
}

.clickableOptions>div {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border: 1px solid black;
    border-radius: .3em;
    text-align: center
}

.clickableOptions .inner {
    display: flex;
    position: absolute;
    top: 1em;
    bottom: 1em;
    left: 1em;
    right: 1em;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
}

.clickableOptions i::before {
    font-size: 2em;
    margin: 0;
}

.clickableOptions>div.active {
    background-color: #000;
    color: #fff;
}

.clickableOptions i {
    flex: 1;
    display: block;
    font-size: 2em;
}

.clickableOptions span {
    font-size: .8em;
    line-height: 1;
}

/* TODO: slider styles */
.sliderContainer {
    display: grid;
    grid-template-columns: 2em auto 4em;
    justify-items: center;
    align-items: center;
    width: 100%;
    max-width: 30em;
    /* background: gray; */
    /* position: relative; */
}

.sliderContainer .slider {
    flex: 1;
    width: 100%;
}

.sliderContainer.disabled label {
    display: none;
}

.sliderContainer.disabled .slider {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none
}

/* custom range input */
input[type=range] {
    -webkit-appearance: none;
    margin: 1em 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: .5em;
    cursor: pointer;
    animate: 0.2s;
    background: #000;
    border: none;
  }
  input[type=range]::-webkit-slider-thumb {
    border: none;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    background: #000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -.75em;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #000;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: .5em;
    cursor: pointer;
    animate: 0.2s;
    background: #000;
    border: none;
  }
  input[type=range]::-moz-range-thumb {
    border: none;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    background: #000;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: .5em;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #000;
    border: none;
    border-radius: 0;
  }
  input[type=range]::-ms-fill-upper {
    background: #000;
    border: none;
    border-radius: 0;
  }
  input[type=range]::-ms-thumb {
    border: none;
    height: 2em;
    width: 2em;
    border-radius: 0;
    background: #000;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #000;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #000;
  }


.toggleView {
    position: fixed;
    z-index: 99;
    right: .8em;
    top: 4em;
    background-color: white;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    line-height: 3em;
    text-align: left;
    background-color: #000;
    color: #fff;
    border: 1px solid white;
    text-align: center;
    /* box-shadow: 0px 0px 20px rgba(255,255,255,.8); */
}

.toggleView i::before {
    margin: 0;
}

.toggleView img {
    position: absolute;
    max-width: 2em;
    max-height: 2em;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.navBar {
    display: flex;
    position: fixed;
    z-index: 2;
    width: 100%;
    padding: .6em;
    padding-bottom: .5em;
    background-color: #000;
    color: #fff;
    bottom: 0;
}

.navBar .tab {
    flex: 1;
    text-align: center;
}

.navBar .tab.active {
    color: #edfb37;
}

.navBar i::before{
    font-size: 1.5em;
}

.navBar .tab img {
    height: 1.5em;
}

.navBar .tab.active img.pin_normal,
.navBar .tab img.pin_active {
    display: none;
}

.navBar .tab.active img.pin_active {
    display: inline-block;
}

.promptContainer {
    position: fixed;
    max-width: 450px;
    margin: 0 auto;
    top: 4em;
    padding: 2em;
    border-radius: .5em;
    box-shadow: 0px 0px 50px rgba(0,0,0,.8);
    background-color: #fff;
    text-align: center;
}

.promptContainer.closed {
    display: none;
}

.promptContainer h2 {
    margin: 0;
    /* margin-bottom: 1em; */
    font-size: 1.5em;
    font-weight: 700;
}

.promptContainer .promptContent {
    margin-top: 1em;
    margin-bottom: 2em;
}

.promptContainer .buttons {
    display: flex;
    /* margin-top: 1em; */
    /* align-content: space-between; */
    justify-content: space-around;
}

@media only screen and (min-width: 1020px) {
    .searchBar{
        padding: 0 12.5%;
    }

    .searchBar .search { 
        padding-left: 0;
    }

    .navBar {
        padding-left: 12.5%;
        padding-right: 12.5%;
    }

    .navBar>div>i{
        margin-left: 1em;
    }

    .filterOptions {
        width: 75%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 850px) {
    .industryContainer .selectIndustries {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 500px) {
    .industryContainer .selectIndustries {
        grid-template-columns: 1fr;
    }
    
    .clickableOptions i::before {
        font-size: 1.5em;
    }

    .promptContainer {
        left: 1em;
        right: 1em;
    }
}

@media only screen and (max-width: 350px) {

    .clickableOptions {
        grid-template-columns: 1fr 1fr;
    }

    .clickableOptions i::before {
        font-size: 2em;
        line-height: 1.5;
    }
}