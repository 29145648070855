.businessProfile {
    background-color: #f7f7f7;
    height: 100%;
    /* padding-bottom: 2.5em; */
    /* display: block; */
    /* padding-bottom: 3em; */
}

/* .businessProfile .topSection, .businessProfile .bottomSection {
    position: relative;
    flex: 1;
    width: 100%;
    height: 50vh;
    overflow: hidden;
} */

.businessProfile .imageContainer {
    height: 40%;
    width: 100%;
    background-color: #000;
}

.businessProfile.white .imageContainer {
    background-color: #fff;
}

.businessProfile .profileImage {
    width: 100%;
    height: 100%;
    /* margin: 1em; */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.businessProfile.fill .profileImage {
    background-size: cover;
}

.businessProfile.logo-false .imageContainer {
    background-color: #fff;
}

.businessProfile.logo-false .profileImage {
    background-size: cover;
}

.businessProfile .icons {
    flex: 0;
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1em;
    padding: 1em;
    color: #000;
    /* text-shadow: 0px 0px 3px rgba(255,255,255,.7); */
    background-color: white;
    /* padding-bottom: 1em; */
    /* background-color: rgba(255,255,255,.5); */
    /* box-shadow: 0px 0px 15px rgba(0,0,0,.5); */
}

.businessProfile.black .icons {
    color: white;
    /* text-shadow: 0px 0px 3px rgba(0,0,0,.7); */
    background-color: black;
}

.businessProfile .icons i::before {
    margin-left: 0;
    margin-right: .5em;
}

.businessProfile.fav-true .favourite {
    cursor: pointer;
    color: red;
}

.businessProfile .bottomSection {
    flex-grow: 1;
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    background-color: #f7f7f7;
    /* height: 50%; */
    /* overflow: auto; */
    width: 100%;
    /* -webkit-overflow-scrolling: touch; */
    padding-bottom: 3em;
}

.businessProfile .details {
    /* display: flex; */
    flex: 0;
    padding: 1em;
}

.businessProfile .details .address {
    flex: 1;
    max-width: 70%;
}

.businessProfile .details .name {
    /* font-weight: bold; */
}

.businessProfile .details .openingTimes {
    position: absolute;
    /* position: relative; */
    right: 1em;
    margin-left: 1em;
    font-size: .9em;
    /* padding: .5em;
    padding-top: 0; */
    bottom: calc(100% - 5em);
    top: 3em;
    transition: .2s bottom ease-out; 
    
    overflow: hidden;
}

.businessProfile .details .openingTimes>span {
    padding: .5em;
    background-color: rgba(255,255,255,0);
    transition: .1s background-color linear;
    transition-delay: .2s;
    cursor: pointer;
}

.businessProfile .details .openingTimes.expanded>span {
    background-color: rgba(255,255,255,.9);
    transition-delay: 0s;
}

.businessProfile .details .openingTimes.expanded {
    
    transition-delay: 0s;
    bottom: 1em;
    /* max-height: 500px; */
    overflow: auto;
}

.businessProfile .details .openingTimes i {
    height: 1.2em;
    line-height: 1.2;
    vertical-align: middle;
    transform: rotate(-90deg);
    transition: .1s transform linear;
}

.businessProfile .details .openingTimes.expanded i {
    transform: rotate(0);
}

.businessProfile .details .openingTimes>div {
    left: 0;
    right: 0;
    padding: .5em;
    padding-top: 0;
    background-color: rgba(255,255,255,0);
    transition: 0s background-color linear;
    transition-delay: .3s;
}

.businessProfile .details .openingTimes.expanded>div {
    background-color: rgba(255,255,255,.9);
    transition-delay: 0s;
}

.businessProfile .details .openingTimes.expanded>div {
    /* max-height: 500px;
    overflow: auto; */
}

.businessProfile .details .openingTimes .day {
    
}

.businessProfile .details .openingTimes .day h4 {
    margin-bottom: 0;
    margin-top: .8em;
}

.businessProfile .details .openingTimes .day:first-of-type h4 {
    margin-top: 0;
}

.businessProfile .details .openingTimes .hide {
    display: none;
}

.businessProfile .quote {
    padding: 0 1em;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: 900;
    text-align: center;
    /* overflow: auto; */
}

.businessProfile .quote .mark {
    font-size: 2em;
    padding: .1em;
    font-weight: bold;
    /* line-height: 1; */
}

.businessProfile .quote .text {
    padding: 0 1em;
}

@media only screen and (min-width: 800px) {
    .businessProfile .profileImage {
        width: 50%;
        margin: 0 auto
    }

    .bottomSection .icons {
        padding-left: 12.5%;
        padding-right: 12.5%;
    }

    .bottomSection .details {
        width: 75%;
        padding-left: 0;
        padding-right: 0;
        align-self: center;
    }

    .bottomSection .quote {
        margin: 0 12.5%;
        padding-left: 0;
        padding-right: 0;
    }
}