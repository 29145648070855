
.loginRegister{
    padding: 1em;
    padding-bottom: 5em;
}

.loginRegister h2 {
    text-align: center;
}

.loginRegister .buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2em;
}

.loginRegister .button {
    min-width: 10em;
    margin: .5em;
}

.weekdays #alwaysOpen {
    margin-top: 1em;
}

@media only screen and (max-width: 500px) {
    .loginRegister .buttons {
        flex-direction: column;
        align-items: center;
    }
}