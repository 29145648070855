.H_ui {
    /* width: 100%;
    height: 100%; */
}

.H_ib {
    /* background-color: red !important; */
    /* width: 10em;
    height: 10em; */
    width: 0;
    height: 0;
    /* transform: translate(100px, 20px) !important; */
}

.H_ib_body {
    /* position: absolute; */
    left: 0px;
    bottom: 0px !important;
    width: 10em;
    /* transform: translateY(-80%); */
    /* height: auto; */
    /* width: 10em;
    height: 10em; */
    background-color: transparent !important;
    /* color: white; */
}

.H_ib_tail {
    display: none;
}

.H_ib_close {
    z-index: 5;
    position: absolute;
    right: 0;
    /* color: black !important; */
}

.H_ib_close::after {
    content: "+";
    position: absolute;
    right: 0;
    color: black;
    font-size: 3em;
    /* border: 1px solid black; */
    /* font-weight: bold; */
    transform: rotate(45deg);
}

.H_ib_close .H_icon {
    color: transparent !important;
    /* background-color: red; */
    display: none;
    /* color: white; */
}

.H_ib_content {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    width: 10em;
    height: 10em;
    color: white;
}

img.bubble-img {
    /* position: absolute; */
    z-index: -1;
    width: 10em !important;
    height: 10em !important;
}

.H_ib_body .details {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    padding: 1em;
    box-sizing: border-box;
    overflow: hidden;
    /* padding-bottom: 80%; */
    top: 0;
    font-size: .6em;
    text-align: center;
    /* background-color: red */
}

.H_ib_body .name {
    flex: 0;
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: bold;
}

.H_ib_body .industry {
    flex: 0;
    line-height: 1.2;
    margin-bottom: .5em;
}

.H_ib_body .address>div {
    flex: 0;
    margin: 0;
    line-height: 1.2;
}

.H_ib_body .url {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 2em; */
    margin-top: .5em;
    /* background: red; */
}

.H_ib_body .url img {
    max-height: 2em;
    width: auto;
}

.H_ib_body .dots {
    flex: 0;
    /* width: 10em; */
    text-align: center;
    /* height: 1em; */
}

.H_ib_body .dots>span {
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: #fff;
    margin: 3px;
    border-radius: 50%;
}